<template>
  <div class="login-component animate__fadeIn">
    <div class="margin-auto center text-center flex">
      <div v-if="!logoSrc" class="logo center text-center">[Company logo]</div>
      <div v-else="" :style="logoStyle" class="logo center text-center"></div>
    </div>

    <h1 class="main-title block text-center">
      {{ translate(subTitle) }}
    </h1>
    <div v-if="shouldShowPrelogin" class="pre-login">
      <slot name="prelogin" :pre-login-complete="{ preLoginComplete }"></slot>
    </div>

    <div v-if="shouldShowForm" class="form-wrapper margin-l-bottom">
      <slot name="form">
        <base-form
          ref="loginForm"
          v-model="loginData"
          :action="formAction"
          method="post"
          :form-path="formSource"
          :expected-fields="2"
          :config="{ formHandling }"
          :general-error-message="'user.loginFailedGenetic'"
          @form:submit-error="loginErrorHandler"
          @form:submit-success="loginSuccessHandler">
          <template #afterButtons></template>
        </base-form>
      </slot>

      <slot name="afterForm"></slot>
    </div>

    <div class="login-footer-links">
      <slot name="footerLinks">
        <slot name="footer-links">
          <div class="login-footer-links-inner flex flex-between">
            <div class="link-wrap">
              <saffron-link to="/" class="">{{
                translateUcFirst("user.resetPasswordCta")
              }}</saffron-link>
            </div>
            <div class="link-wrap">
              <saffron-link :to="{ name: 'index' }" class="">{{
                translateUcFirst("core.backHome")
              }}</saffron-link>
            </div>
          </div>
        </slot>
      </slot>
    </div>

    <spinner
      v-if="isFetchingProfile"
      overlay="fixed"
      :text="safeTranslate('user.loginInProgress')"></spinner>
  </div>
</template>

<script>
// todo: default texts, props etc

export default {
  props: {
    /**
     * Where to redirect after login
     **/
    loginRedirect: {
      type: [String, Object, Function],
      default: "/",
    },
    /**
     * Where to redirect if activation is required
     **/
    activationRedirect: {
      type: [String, Object, Function],
      default: { name: "user-activation" },
    },
    subTitle: {
      type: String,
      default: "core.user.loginSubTitle",
    },
    /**
     * Relative path for the logo
     * If false, will use placeholder
     **/
    logoSrc: {
      type: [String, Boolean],
      default: "core/images/logos/main.png",
    },
    /**
     * Api form source (url to get the form)
     **/
    formSource: {
      type: String,
      default: "form/user-login",
    },
    /**
     * Where does the login form post to. relative API path
     **/
    formAction: {
      type: String,
      default: "/token/refresh/userPassword",
    },
    showPrelogin: {
      type: [Boolean],
      default: true,
    },
    showForm: {
      type: [Boolean],
      default: true,
    },
  },
  emits: ["login:success"],
  data: function () {
    return {
      preLoginComplete: false,
      loginData: {},
      formContext: "context2",
      formHandling: {
        /*
         preflight: [
         {
         type: 'validation',
         options: {}
         },
         {
         type: 'server',
         options: {
         url : 'test',
         additionalData : {
         'some': 'key'
         },
         onStart : (form) => {

         },
         onEnd : (form, result) => {
         //  if (result.isError) {
         //   form.generalErrorMessageInternal = 'test error message';
         //    form.showSubmitError = true;
         //  }
         }
         }
         },
         {
         type: 'confirmation',
         options: {
         //  title: 'configured title',
         //   content: 'configured content',
         //    labels: {
         //     cancelButton: 'CANCEL-',
         //    okButton: 'OK-'
         //   }
         }
         },
         ],
         //   requestAdapter: 'default',
         //   responseAdapter: 'default'
         //    selfHandling : false,
         loadingBehaviour : {
         'overlay': true,
         'text': 'core.form.loading'
         },
         //   successBehaviour : 'default', //todo
         //   errorBehaviour : 'default',
         */
      },
    };
  },
  computed: {
    isFetchingProfile() {
      return this.$store.getters["user/fetchingProfile"];
    },
    logoStyle() {
      if (!this.logoSrc) {
        return [];
      }

      let url = utilities.requireAsset(this.logoSrc);
      return {
        backgroundImage: "url('" + url + "')",
        height: "80px",
        width: "80px",
      };
    },
    shouldShowPrelogin() {
      return this.showPrelogin && !this.preLoginComplete;
    },
    shouldShowForm() {
      return this.showForm || this.preLoginComplete;
    },
    translatedSubTitle() {
      return this.safeTranslate(this.subTitle);
    },
  },
  watch: {
    preLoginComplete(newVal, oldVal) {},
  },
  created() {},
  methods: {
    ourFormCallback() {},
    testMethod(test) {
      console.log("test method!", test);
    },
    setCredentials(credentials) {
      this.loginData.password = credentials.password;
      this.loginData.email = credentials.email;
      return true;
    },
    loginByState() {
      this.$refs.loginForm.submit();
    },
    async loginSuccessHandler(eventData) {
      this.$store.commit("user/setToken", eventData.resultData.jwt);
      this.$store.commit("user/setRefreshToken", eventData.resultData.refreshToken);

      this.$store.commit(
        "user/setRefreshTokenExpires",
        eventData.resultData.refreshTokenExpires
      );

      let result = await this.$store.dispatch("user/updateUserProfile");

      if (result === false) {
        this.$saffron.ui.notification(
          this.safeTranslate("user.fetchProfileException"),
          "danger"
        );
        return false;
      }

      // we are logged in and are redirecting
      this.$s.ui.notification("core.user.loginCompleteNotification", "success");

      // in case no redirect provided
      if (!this.$route || !this.$route.query || !this.$route.query.redirect) {
        this.$router.push(this.loginRedirect);
        return true;
      }
      // we have route query, dictating the redirect
      let params = this.$route.query.redirectParams
        ? this.$route.query.redirectParams
        : {};
      let resolvedRoute = false;
      try {
        resolvedRoute = this.$router.resolve({ name: this.$route.query.redirect });
      } catch (e) {}

      // try to resolve the name
      if (resolvedRoute) {
        // case of route name
        this.$router.push({ name: this.$route.query.redirect, params });
      } else {
        // case of plain string
        this.$router.push(this.$route.query.redirect);
      }
    },
    async activationRequiredHandler(eventData) {
      if (typeof this.activationRedirect === "function") {
        this.$router.push(await this.activationRedirect());
        return true;
      }

      this.$router.push(this.activationRedirect);
    },
    async loginErrorHandler(eventData) {
      let res = eventData.result;

      if (res.data === 403) {
        this.$nextTick(() => {
          this.$refs["loginForm"].setGeneralErrorMessage("core.user.credentialsRejected");
        });
        // login rejected
      }

      if (res.data === 412) {
        this.activationRequiredHandler(eventData);
      }
    },
  },
};
</script>

<style scoped lang="scss">
// TODO: mixin imports, especially directional
/*

$breakpoint-small: 640px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;
 */

.logo {
  flex: auto;
  height: 100px;
  width: auto;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: var(--margin-xl);

  @media (max-width: 767px) {
    height: 60px;
    width: auto;
    background-size: auto 60%;
    margin-bottom: var(--margin-m);
  }
}

.main-title {
  margin-bottom: var(--margin-l);

  @media (max-width: 767px) {
    font-size: var(--font-2xl);
    margin-bottom: var(--margin-xl);
  }
}

.login-footer-links-inner {
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.link-wrap {
  @media (max-width: 767px) {
    margin-bottom: var(--margin-m);
  }

  &:last-of-type {
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
}
.login-component {
  max-width: calc(90vw - 60px);
  width: 550px;
  flex: 550px;

  @media screen and (max-width: 640px) {
    max-width: calc(90vw - 60px);
    width: 80vw;
    flex: 80vw;
  }

  @media screen and (max-width: 400px) {
    max-width: calc(90vw - 20px);
    width: 90vw;
    flex: 90vw;
  }

  .uk-card-body {
    padding-top: 0;
    padding-bottom: 0;
    @media screen and (max-width: 400px) {
      padding: 20px 10px;
    }
  }

  .uk-logo {
    display: inline-block;
  }

  .login-footer-links {
    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }

    .link-wrap {
      @media screen and (max-width: 640px) {
        flex: 100%;
      }
    }
  }
}
</style>
